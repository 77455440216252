.dropdown_container {
  position: relative;
  z-index: 998;
  width: 0;
  height: 0;
}

.left {
  float: left;
  align-self: start;
}

.right {
  float: right;
  align-self: end;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.hidden {
   display: none !important;
}

.dropdown-button {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: bold;
}

.dropdown-button:hover {
  background-color: var(--theme-background-grey);
}
