.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  position: relative;


  border-top: 1px solid var(--shadow);
  bottom: 0;
  background-color: var(--theme-background-grey);
}

.footer-link {
  display: flex;
  align-items: center;
  margin: 0 4px;
  cursor: pointer;
}

.footer-link:hover {
  color: var(--theme-dark);
  font-weight: bold;
  text-decoration: underline;
}
