/* TODO Add site wide styles */

* {
box-sizing: border-box;
font-family: Quicksand;
}

body {
  margin: 0;
  width: 100%;
}

html, body {
  overflow: hidden;
  overscroll-behavior: contain;
  position: fixed;
}

/* html
,body
,html *
,body *
{
  overscroll-behavior: none !important;
} */

:focus:not(:focus-visible) { outline: none }

#root {
  height:100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

:root {
  --theme-default: #5A4AE3;
  --theme-dark: #444066;
  --theme-medium: #473BB3;
  --theme-light: #9C93EA;
  --theme-grey: #b5b5b5;
  --icon-grey: #d1d1d1;
  --shadow: rgb(0,0,0,.1);
  --theme-background-grey: #EFEFEF;
}

img {
  object-fit: contain;
  object-position: 50%;
  resize: vertical;
  border-radius: 5px;
}
