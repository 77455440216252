.splash-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--icon-grey);
  font-weight: 600;
}

.splash-top {
  display: flex;
  justify-content: space-between;
  /* height: 50px; */
  width: 100%;
  background-color: var(--theme-medium);
  border-bottom: 1px solid var(--shadow);
}


.splash-main {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  background-color: var(--theme-medium);
}

.logo, .logo-title{
  height: 100%;
}

.logo {
  width: 40%;
}

.logo-title {
width: 60%;
}

.logo-container {
  display: flex;
  align-items: center;
  height:100px;
  padding: 10px;

  background-color: var(--theme-background-grey);
  border-radius: 5px;
}

.join-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--theme-background-grey);
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  width: 250px;
  height: 40px;
  background-color: red;
  border-radius: 12px;
  border: none;
}

.join-button:hover {
  background-color: red;
}

.dancers-img {
  width: 250px;
  transition: .5s
}

.join-button:hover + .dancers-img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  transition: .5s
}

.join-button:hover {
  box-shadow: 2px 2px 2px 2px var(--shadow);
}
