.modal-center-split__mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* margin-left: 5px; */
}

.modal-left-section__mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal-left-section__mobile {
  height: calc(100% - 25vh - 26px); /* 25vh for the map, and X px for nthe header */
  overscroll-behavior-y: none;
}
.modal-map-section__mobile {
  width: 100%;
  height: 25vh;
}


.modal-eventscroll {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal-eventscroll::-webkit-scrollbar {
  display: none;
}

.modal-venueline-body__mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
  /* padding-left: 2px; */
  /* margin-bottom: 2px; */
  /* border-radius: 5px; */
  height: 35px;
}


.add-venue-button {
  color: white;
  background-color: var(--theme-default);
  border-radius:4px;
  font-weight: bold;
}

.modal-clickable-icon {
  display: flex;
  color: var(--icon-grey);
  transition: .3s;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin: 1px;
  padding: 4px;

  border-radius: 5px;
  transition: .3s;
}

.modal-clickable-icon:hover {
  background-color: var(--theme-default);
  cursor: pointer;
  box-shadow: 1px 1px 1px var(--shadow);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-venue-header__mobile {
  font-weight: bold;
  width: 100%;
  padding-bottom: 5px;
  /* margin-bottom: 5px; */
  font-size: 14;
  border-bottom: 1px solid var(--shadow);
}



.page-button {
  display: flex;
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  transition: .3s;
  align-items: center;
  justify-content: center;

  margin: 1px;
  padding: 4px;

  border-radius: 5px;
  transition: .3s;
  background-color: var(--theme-default);
}

.page-button:hover {
  cursor: pointer;
  background-color: var(--theme-dark);
  box-shadow: 1px 1px 1px var(--shadow);
}
