.navbar {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 0;
  width: 100%;
  height: 40px;

  background-color: var(--theme-default);
  border-bottom: 1px solid var(--shadow);
  margin-bottom: 15px;
}

.navbar * {
  color: var(--icon-grey)
}

.left-bar-logo {
  width: 20%;
}

.left-bar-logo-title{
  width: 80%;
}

.left-bar-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-start;
  height: calc(100%);
  background-color: white;
  max-width: 217px;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.left-bar-logo-container:hover {
  box-shadow: 1px 1px 3px 1px var(--shadow);
  transition: .3s;
}

.icon-button {
  display: flex;
  width: 32px;
  height: 32px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: .5s;
  color: var(--icon-grey);
  margin: 2px;
  position:relative;
}

.icon-button:hover {
  background-color: var(--theme-dark);
}

.search-area {
  display: flex;
  align-items: center;
  max-width: 250px;
  position: relative;
  background-color: var(--theme-light);
  transition: .5s;
  cursor:text;

  border-radius: 5px;
}

.search-area:focus-within {
  background-color: white;
  border: none;
  color: black;
}

.search-input {
  width: 100%;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  background-color: transparent;
  outline: none;
  border: none;

}

.search-input:focus {
  color: black;
}


.navbar-rhs {
  display: flex;
  align-items: center;
  justify-self: flex-end;
  justify-content: space-evenly;
  position: relative;
}

.user-dropdown {
  background-color: white;
  transform: translateY(30px) translateX(-100%);
  box-shadow: 1px 1px 1px 1px var(--shadow);
  border-radius: 5px;
  width: 150px;
}
.user-dropdown div {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  cursor: pointer;
}
.user-dropdown div:hover {
  background-color: var(--theme-background-grey);
  font-weight: bold;
  color: var(--theme-dark);
}

.create-dropdown {
  background-color: white;
  transform: translateY(30px) translateX(-100%);
  box-shadow: 1px 1px 1px 1px var(--shadow);
  border-radius: 5px;
  width: 150px;
}

.create-dropdown div {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  cursor: pointer;
  color: black;
}

.create-dropdown div:hover {
  background-color: var(--theme-background-grey);
  font-weight: bold;
  color: var(--theme-dark);
}

.user-dropdown * {
  color: black;
}
