#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255, 0.5);
  z-index: 999;
}

#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
}



.hidden {
  display: none !important;
}

.modal-container {
  position: absolute;
  z-index: 1000;
  padding: 37px 37px;
  display: flex;
  flex-flow:column;
  max-width: 700px;
  max-height: 80%;
  background-color:white;
  transition: bottom .2s ease-out;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 10%);
  border: 1px solid var(--shadow);
}

#login__desktop.modal-container,
#signup__desktop.modal-container {
  width: 50vw;
  max-width: 500px;
}

#create-event__desktop.modal-container,
#edit-event__desktop.modal-container,
#create-venue__desktop.modal-container {
  width: 60vw;
  max-width: 800px;
}

#login__mobile.modal-container,
#signup__mobile.modal-container
{
  width: 90vw;
}


#create-event__mobile.modal-container,
#edit-event__mobile.modal-container
{
  width: 90vw;
  height: 90%;
}

#create-venue__mobile.modal-container {
  width: 90vw;;
}

/* #create-event__mobile.modal-content {
  height: 60vh;
} */

#modal-header {
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  margin-bottom: 4px;
  font-weight: 600;
  align-items: center;
}

#modal-logo {
  height: 50px;
}

#modal-close-button {
  position: absolute;
  right: 20px;
  height: 16px;
  width: 16px;
  opacity: .3;
}

#modal-close-button:hover {
  cursor: pointer;
  opacity: .6;
}

.modal-content {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overscroll-behavior-y: none;
}

.modal-content::-webkit-scrollbar {
  display: none;
}

.input-field {
  display: flex;
}

.errors {
  height: fit-content;
  border: none;
}
.error {
  color: red;
  font-style: italic;
  font-size: 14px;
}


form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
}

label {
  position: absolute;
  left: 10%;
  top: 0;
  /* transform: translateY(-50%); */
  background-color: white;
  padding: 0 2px;
}

form > div {
  position:relative;
  width: 100%;
  /* height: 30px; */
  margin: 4px 0;
  /* padding-top: 5px; */
  border: 1px solid rgb(118,118,118);
  border-radius: 3px;

}

.datetime-input {
  display: flex;
  border: none;
}
.time-input {
  width: 150px;
  margin-left: 2px;
}

.textarea-input {
  width: 100%;
  resize: none;
  text-align: top;
  padding: 2px;
  height: 60px;
  /* overflow-y: scroll; */
  -ms-overflow-style: none;
  scrollbar-width: none;
  outline: none;
}

.textarea-input::-webkit-scrollbar {
  display: none;
}

input {
  flex-grow: 1;
  width: 100%;
  /* margin: 2px; */
  outline: none;
  border: none;
  height: 30px;
  background: none;
}

.split-input {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.datetime-input > input {
  border: 1px solid rgb(118,118,118);
  border-radius: 3px;
}

button {
  /* height: 30px; */
  margin-top: 4px;
  padding: 2px;
  background-color: var(--theme-default);
  color: var(--theme-background-grey);
  font-weight: 600;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: .3s;
}

form button {
  height: 30px
}

.submit-button {
  height: 30px;
}

button:hover {
  box-shadow: 2px 2px 2px var(--shadow);
  background-color: var(--theme-dark);
}

.modal-fieldset {
  padding: 4px;
  font-size: 14px;
}

.checkbox-line {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1px 0;
}

.checkbox-input {
  display:flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid rgb( 118,118,118);
  border-radius: 3px;
  margin-right: 4px;
  color: white;
  cursor: pointer;
}

.unchecked:hover {
  color: var(--icon-grey);
}

.checked {
  background-color: var(--theme-default);
}

.checked:hover {
  background-color: var(--theme-light);
}

.modal-map-split {
  display: flex;
}
