.map {
  position: relative;
  /* z-index: -1; */
  height: 100%;
  width: 100%;
  box-shadow: 2px 2px rgba(0,0,0,.5) inset;
  border-top-right-radius: 5px;
}

.map div {
  width: fit-content
}

.map:before {
  box-shadow: 4px 0 4px -4px rgba(0, 0, 0, 0.5) inset;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 4px;
  z-index: 1;
}

.map div:focus-visible {
  outline: none;
}

.inline-map {
  height: calc(min(40vh,200px));
  width: 100%;
}

.pac-item {
  /* display: flex; */
  overflow-wrap: break-word;
  height: fit-content;
  /* text-overflow: unset; */
  white-space: pre-line;
}
