.modal-center-split {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  height: 100%;
  /* margin-left: 5px; */
}

.modal-map-section {
  width: 60%;
  height: 55vh;
  /* height: 500px; */
}

.modal-venueline-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* padding-right: 5px; */
  margin-left: 2px;
  margin-right: 5px;
  border-radius: 5px;
  width: calc(100%-7px);
}

.modal-venueline-container:hover {
  box-shadow: 1px 1px 3px 1px var(--shadow);
  cursor: pointer;
  /* transition: .1s; */
}

.modal-venueline-body__desktop {
  display: flex;
  justify-content: space-between;
  margin-right: 5px;
  height: 35px;
  /* margin-bottom: 5px; */
}

.modal-venueline-details {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  /* margin-left: 10px; */
  /* flex-grow: 1; */
}



.add-venue-button {
  color: white;
  background-color: var(--theme-default);
  border-radius:4px;
  font-weight: bold;
}

.modal-clickable-icon {
  display: flex;
  color: var(--icon-grey);
  transition: .3s;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin: 1px;
  padding: 4px;

  border-radius: 5px;
  transition: .3s;
}

.modal-clickable-icon:hover {
  background-color: var(--theme-default);
  cursor: pointer;
  box-shadow: 1px 1px 1px var(--shadow);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-venue-header {
  font-weight: bold;
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-size: 14;
  border-bottom: 1px solid var(--shadow);
}

.modal-left-section {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.page-button {
  display: flex;
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  transition: .3s;
  align-items: center;
  justify-content: center;

  margin: 1px;
  padding: 4px;

  border-radius: 5px;
  transition: .3s;
  background-color: var(--theme-default);
}

.page-button:hover {
  cursor: pointer;
  background-color: var(--theme-dark);
  box-shadow: 1px 1px 1px var(--shadow);
}
